<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">班级审核</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级参数</a>
        </span>
      </div>
      <div class="framePage-body addClassSubject">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexcc">
              <el-form
                ref="Form"
                :model="Form"
                :rules="rules"
                label-width="13rem"
              >
                <el-form-item
                  label="班级编码"
                  prop="projectCode"
                  class="form-item"
                >
                  <el-input
                    v-model="Form.projectCode"
                    disabled
                    style="width: 250px"
                  />
                </el-form-item>
                <el-form-item
                  label="班级名称"
                  prop="projectName"
                  class="form-item"
                >
                  <el-input
                    v-model="Form.projectName"
                    disabled
                    style="width: 250px"
                  />
                </el-form-item>
                <el-divider></el-divider>

                <el-form-item
                  prop="paraIsRoll"
                  class="form-item"
                  label="点名认证"
                  v-show="!Form.allowDrag"
                >
                  <el-radio-group
                    v-model="Form.paraIsRoll"
                    disabled
                    @change="paraIsRollChange"
                  >
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  prop="paraRollSecond"
                  class="form-item"
                  label="点名读秒"
                  v-show="Form.paraIsRoll"
                >
                  <el-input
                    el-input
                    size="small"
                    style="width: 200px"
                    v-model="Form.paraRollSecond"
                  ></el-input>
                  <span style="margin-left: 10px">秒</span>
                </el-form-item>
                <el-form-item
                  prop="paraRollOrgFailureNum"
                  class="form-item"
                  label="机构点名失败"
                  v-show="Form.paraIsRoll"
                >
                  <el-input
                    size="small"
                    style="width: 200px"
                    v-model="Form.paraRollOrgFailureNum"
                  ></el-input>
                  <span style="margin-left: 10px">次失去资格</span>
                </el-form-item>
                <el-form-item
                  prop="paraRollGovFailureNum"
                  class="form-item"
                  label="监管点名失败"
                  v-show="Form.paraIsRoll"
                >
                  <el-input
                    size="small"
                    style="width: 200px"
                    v-model="Form.paraRollGovFailureNum"
                  ></el-input>
                  <span style="margin-left: 10px">次失去资格</span>
                </el-form-item>
                <el-form-item
                  prop="paraRollRandomNum"
                  class="form-item"
                  label="课件随机点名次数"
                  v-show="Form.paraIsRoll"
                >
                  <el-input
                    size="small"
                    style="width: 200px"
                    v-model="Form.paraRollRandomNum"
                  ></el-input>
                  <span style="margin-left: 10px">次</span>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item class="form-item" label="每日学习时长限制">
                  <el-radio-group
                    @change="handleclear()"
                    disabled
                    v-model="Form.studyLength"
                  >
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  class="form-item"
                  label="学习时长限制类型"
                  v-if="this.Form.studyLength"
                >
                  <el-radio-group
                    v-model="Form.xxsjlx"
                   disabled
                  >
                    <el-radio :label="10">按课时</el-radio>
                    <el-radio :label="30">按小时</el-radio>
                  </el-radio-group>
                </el-form-item>
                 <div v-if="Form.studyLength">
                  <div
                    class="df"
                    v-for="(item, index) in Form.paraTimeLimit"
                    :key="index"
                  >
                    <div style="display: flex">
                      <el-form
                        ref="Form1"
                        :model="item"
                        label-width="13rem"
                        style="
                          display: flex;
                          flex-direction: row;
                          flex-wrap: nowrap;
                        "
                      >
                        <el-form-item
                          class="form-item"
                          label="人群类型名称"
                          prop="personType"
                          :rules="[
                            {
                              required: Form.xxsjlx == '30' ? true : false,
                              message: '请选择人群类型名称',
                              trigger: 'change',
                            },
                          ]"
                        >
                          <el-select
                            v-model="item.personType"
                            clearable
                            placeholder="请选择"
                            style="width: 150px"
                            size="small"
                            disabled
                            @change="
                              (val) => {
                                changePersonType(val, index);
                              }
                            "
                          >
                            <el-option
                              v-for="item1 in LearningstateList"
                              :key="item1.value"
                              :label="item1.label"
                              :value="item1.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          class="form-item"
                          label-width="0"
                          style="padding-left: 0"
                        >
                          <div style="margin-left: 20px" class="flexac">
                            <span style="margin-right: 10px">每天学习</span>
                            <el-input-number
                              size="small"
                              v-model="item.timeLimit"
                              :precision="Form.xxsjlx == '30' ? 1 : 0"
                              :step="1"
                              :min="1"
                            ></el-input-number>
                            <!-- <span style="margin: 0 23px 0 5px">课时</span> -->
                            <span style="margin: 0 23px 0 5px">{{
                              Form.xxsjlx == 10 ? "课时" : "小时"
                            }}</span>
                            
                          </div>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>

                  <!-- <el-form-item class="form-item" label="每天学习"></el-form-item> -->
                </div>
              </el-form>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">返回</el-button>
              <el-button @click="seveData('ruleForm')" class="bgc-bv"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "SupervisionEnd/addClassSubject",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入负责人电话"));
      } else {
        callback();
      }
    };

    return {
      id: "",
      stu: "",
      LearningstateList: [], //人群类型
      Form: {
        projectCode: "",
        projectName: "",
        isIdCard: true,
        paraIsLogin: false,
        paraIsLoginEvery: false,
        paraIsMiddle: false,
        paraIsMiddleEvery: false,
        paraIsAfter: false,
        paraIsAfterEvery: false,
        unitTestFace: false, // 单元测试是否签到
        paraIsRoll: false,
        paraRollSecond: "",
        paraRollOrgFailureNum: "",
        paraRollGovFailureNum: "",
        paraRollRandomNum: "2",
        paraRollVoiceRemind: false,
        paraIsSnapshots: false,
        paraIsPaper: false,
        paraIsAnalysis: false,
        paraIsExamAnalysis: false,
        unitTestAnalysis: false, //单元测试答案解析
        paraIsEvaluate: false,
        paraOrderStudy: false,
        paraIsRandom: false,
        paraExamNumLimit: false,
        studyLength: false,
        paraTimeLimit: [],
        paraExamMaxNum: "",
        paraRandomTimeMin: "",
        paraRandomNum: "",
        paraTime: "",
        paraExamHint: "",
        xxsjlx: 10, // 每日学习时长限制 - 学习时长限制类型
        obj: {
          personType: "",
          timeLimit: "",
        },
        // paraSpecialConditions: false,// 完成进度是否
        // paraProgress:'80' // 完成进度输入框默认值
        paraCompleteCondition: false, //特殊完成条件
        paraCompleteConditionNum: "80", //百分比
        paraIsPopQuestion: false, // 是否弹题
        paraPopTimeLimit: 10,
        paraPopNum: 3,
        allowDrag: false,
      },
      // ProgressDisabled: true, // 完成进度输入框禁用状态
      rules: {
        paraIsLogin: [{ required: true, trigger: "change" }],
        paraIsLoginEvery: [{ required: true, trigger: "change" }],
        paraIsMiddle: [{ required: true, trigger: "change" }],
        paraIsMiddleEvery: [{ required: true, trigger: "change" }],
        paraIsAfter: [{ required: true, trigger: "change" }],
        paraIsAfterEvery: [{ required: true, trigger: "change" }],
        unitTestFace: [{ required: true, trigger: "change" }], //单元测试是否签到
        paraIsSnapshots: [{ required: true, trigger: "change" }],
        paraIsPaper: [{ required: true, trigger: "change" }],
        paraIsAnalysis: [{ required: true, trigger: "change" }],
        paraIsExamAnalysis: [{ required: true, trigger: "change" }],
        unitTestAnalysis: [{ required: true, trigger: "change" }], //单元测试显示答案解析
        paraIsEvaluate: [{ required: true, trigger: "change" }],
        paraOrderStudy: [{ required: true, trigger: "change" }],
        paraTimeLimit: [{ required: true, trigger: "change" }],
        paraIsRandom: [{ required: true, trigger: "change" }],
        paraIsSnapshots: [
          {
            required: true,
            trigger: "change",
            message: "请选择人脸识别防挂课时长",
          },
        ],
        paraRandomNum: [
          { required: true, trigger: "blur", message: "请输入随机次数" },
        ],
        paraTime: [{ required: true, trigger: "blur", message: "请输入分钟" }],
        paraRandomTimeMin: [
          { required: true, trigger: "blur", message: "请输入最小时间" },
        ],
        paraRollSecond: [{ validator: this.validatePass1, trigger: "blur" }],
        paraRollOrgFailureNum: [
          { validator: this.validatePass2, trigger: "blur" },
        ],
        paraRollGovFailureNum: [
          { validator: this.validatePass3, trigger: "blur" },
        ],
        paraRollRandomNum: [{ validator: this.validatePass4, trigger: "blur" }],
        paraCompleteCondition: [
          { validator: this.validatePass5, trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getLearningstateList();
    this.id = this.$route.query.projectId;
    this.stu = this.$route.query.stu;
    if (this.$route.query.stu == "edit") {
      this.getDetail(this.id);
    } else {
      this.Form.paraTimeLimit.push({ ...this.Form.obj });
    }
  },
  methods: {
    validatePass1(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;

      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入点名读秒"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("点名读秒必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass2(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;
      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入机构点名失败几次失去资格"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("次数必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass3(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;
      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入监管点名失败几次失去资格"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("次数必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass4(rule, value, callback) {
      let reg = /^[1-9]?\d*$/;
      if (this.Form.paraIsRoll) {
        if (value === "") {
          callback(new Error("请输入课件随机点名次数"));
        } else if (!reg.test(value) || Number(value) < 1) {
          callback(new Error("次数必须大于等于1的正整数!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    validatePass5(rule, value, callback) {
      let reg = /^(([1-9]\d{1})||\d{1})(\.\d{1})?$/;
      if (this.Form.paraCompleteCondition) {
        if (this.Form.paraCompleteConditionNum == "") {
          callback(new Error("请输入完成视频时长的百分比"));
        } else if (!reg.test(this.Form.paraCompleteConditionNum)) {
          callback(new Error("请输入0~100之间数值，支持1位小数"));
        } else if (this.Form.paraCompleteConditionNum == 0) {
          callback(new Error("请输入0~100之间数值，支持1位小数"));
        }
        {
          callback();
        }
      } else {
        this.Form.paraCompleteConditionNum = "80";
        callback();
      }
    },

    paraIsLoginTipsChange(val) {
      if (val == false) {
        this.Form.paraRandomTimeMin = "";
        this.Form.paraRandomNum = "";
        this.Form.paraTime = "";
        this.Form.paraIsRandom = false;
      }
    },
    paraIsRollChange(val) {
      if (val == false) {
        this.Form.paraRollSecond = "";
        this.Form.paraRollOrgFailureNum = "";
        this.Form.paraRollGovFailureNum = "";
        this.Form.paraRollRandomNum = "";
        (this.Form.voice = false),
          this.$refs["Form"].clearValidate("paraRollSecond");
        this.$refs["Form"].clearValidate("paraRollOrgFailureNum");
        this.$refs["Form"].clearValidate("paraRollGovFailureNum");
        this.$refs["Form"].clearValidate("paraRollRandomNum");
      } else {
        this.Form.paraIsLogin = false;
        this.Form.paraIsLoginEvery = false;
        this.Form.paraIsSnapshots = false;
      }
    },
    paraIsDragChange(val) {
      if (val == true) {
        this.Form.paraIsRoll = false;
        this.Form.paraIsSnapshots = false;
        this.Form.paraRollSecond = "";
        this.Form.paraRollOrgFailureNum = "";
        this.Form.paraRollGovFailureNum = "";
        this.Form.paraRollRandomNum = "";
        (this.Form.voice = false),
          this.$refs["Form"].clearValidate("paraRollSecond");
        this.$refs["Form"].clearValidate("paraRollOrgFailureNum");
        this.$refs["Form"].clearValidate("paraRollGovFailureNum");
        this.$refs["Form"].clearValidate("paraRollRandomNum");
      }
    },
    getLearningstateList() {
      const LearningstateList = this.$setDictionary("CROWDTYPE", "list");
      const list = [];
      for (const key in LearningstateList) {
        list.push({
          value: key,
          label: LearningstateList[key],
        });
      }
      this.LearningstateList = list;
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
           if(this.Form.studyLength) {
             if (this.check1()) {
            this.doAjax();
          }
          } else {
             this.doAjax();
          }
        }
      });
    },
     //校验学员类型
    check1() {
      const cardform = this.$refs["Form1"];
      let stu = true;
      for (let i = 0; i < this.Form.paraTimeLimit.length; i++) {
        cardform[i].validate((valid1) => {
          if (valid1 == false) {
            stu = false;
          }
        });
      }
      return stu;
    },
    //删除人群类型名称
    deleteItem(item, index) {
      this.Form.paraTimeLimit.splice(index, 1);
    },
    //新增人群类型名称
    addItem() {
      this.Form.paraTimeLimit.push({ ...this.Form.obj });
    },
    propclear() {
      this.$refs.Form.clearValidate("paraIsSnapshots");
      this.$refs.Form.clearValidate("paraRandomNum");
      this.$refs.Form.clearValidate("paraTime");
      this.Form.paraRandomTimeMin = "";
      this.Form.paraRandomNum = "";
      this.Form.paraTime = "";
    },
    // 点否时清除 之前选是时 下面的数据
    handleclear() {
      if (this.Form.paraIsLogin == false) {
        this.Form.paraIsLoginEvery = false;
      }
      if (this.Form.paraIsMiddle == false) {
        this.Form.paraIsMiddleEvery = false;
      }
      if (this.Form.paraIsAfter == false) {
        this.Form.paraIsAfterEvery = false;
      }
      if (this.Form.paraExamNumLimit == false) {
        this.Form.paraExamMaxNum = "";
        this.Form.paraExamHint = "";
      }
      if (this.Form.studyLength == false) {
        this.Form.paraTimeLimit = [{}];
      }
      if (this.Form.paraIsPopQuestion == false) {
        this.Form.paraPopTimeLimit = "";
        this.Form.paraPopNum = "";
      }
    },
    doAjax() {
      let parmar = {
        isIdCard: this.Form.isIdCard,
        paraExamHint: this.Form.paraExamHint,
        paraExamMaxNum: this.Form.paraExamMaxNum,
        paraExamNumLimit: this.Form.paraExamNumLimit,
        paraId: this.Form.paraId,
        paraIsAfter: this.Form.paraIsAfter,
        paraIsAfterEvery:
          this.Form.paraIsAfter == false ? false : this.Form.paraIsAfterEvery,
        unitTestFace: this.Form.unitTestFace, //单元测试是否签到
        paraIsAnalysis: this.Form.paraIsAnalysis,
        paraIsExamAnalysis: this.Form.paraIsExamAnalysis,
        unitTestAnalysis: this.Form.unitTestAnalysis, //单元测试答案解析
        paraIsEvaluate: this.Form.paraIsEvaluate,
        paraIsLogin: this.Form.paraIsLogin,
        paraIsLoginEvery:
          this.Form.paraIsLogin == false ? false : this.Form.paraIsLoginEvery,
        paraIsMiddle: this.Form.paraIsMiddle,
        paraIsMiddleEvery: this.Form.paraIsMiddleEvery,
        paraIsPaper: this.Form.paraIsPaper,
        paraIsRandom: this.Form.paraIsRandom,
        paraIsLoginTips: false,
        paraOrderStudy: this.Form.paraOrderStudy,
        paraIsSnapshots: this.Form.paraIsSnapshots,
        trainTypeId: this.Form.trainTypeId,
        // paraRandomTimeMin: this.Form.paraRandomTimeMin,
        // paraRandomNum: this.Form.paraRandomNum,
        paraTime: this.Form.paraTime,
      };
      if (this.Form.paraIsPopQuestion) {
        parmar.paraIsPopQuestion = this.Form.paraIsPopQuestion;
        parmar.paraPopTimeLimit = this.Form.paraPopTimeLimit;
        parmar.paraPopNum = this.Form.paraPopNum;
      } else {
        parmar.paraIsPopQuestion = this.Form.paraIsPopQuestion;
        parmar.paraPopTimeLimit = "";
        parmar.paraPopNum = "";
      }
      if (this.Form.allowDrag) {
        parmar.allowDrag = this.Form.allowDrag;
      } else {
        if (this.Form.paraIsRoll == true) {
          parmar.paraIsRoll = this.Form.paraIsRoll;
          parmar.paraRollOrgFailureNum = this.Form.paraRollOrgFailureNum;
          parmar.paraRollGovFailureNum = this.Form.paraRollGovFailureNum;
          parmar.paraRollRandomNum = this.Form.paraRollRandomNum;
          parmar.paraRollSecond = this.Form.paraRollSecond;
          parmar.paraRollVoiceRemind = this.Form.paraRollVoiceRemind;
        } else {
          parmar.paraIsRoll = this.Form.paraIsRoll;
          parmar.paraRollOrgFailureNum = this.Form.paraRollOrgFailureNum;
          parmar.paraRollGovFailureNum = this.Form.paraRollGovFailureNum;
          parmar.paraRollRandomNum = "";
          parmar.paraRollSecond = this.Form.paraRollSecond;
          parmar.paraRollVoiceRemind = this.Form.paraRollVoiceRemind;
        }
        if (this.Form.paraIsRandom == true) {
          parmar.paraRandomTimeMin = this.Form.paraRandomTimeMin;
          parmar.paraRandomNum = this.Form.paraRandomNum;
        } else {
          parmar.paraRandomTimeMin = "";
          parmar.paraRandomNum = "";
        }
      }
      if (this.Form.paraCompleteCondition) {
        parmar.paraCompleteCondition = this.Form.paraCompleteConditionNum;
      }
      if (this.Form.studyLength == false) {
        parmar.paraTimeLimit = "[{}]";
      } else {
        // 学习时长限制类型：10-按课时；30-按小时
        parmar.studyTimeLimitType = this.Form.xxsjlx;
        parmar.paraTimeLimit = JSON.stringify(this.Form.paraTimeLimit);
      }
      if (this.Form.areaId) {
        parmar.areaId = this.Form.areaId;
      }
      if (this.stu == "edit") {
        parmar.projectId = this.id;
      }

      this.$post(
        "/projectCourseParam/edu-project-course-parameter-private/insert",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/SupervisionEnd/sjzRegulator/classReview",
                query: {
                  refrsh: true,
                },
              });
            }, 300);
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    getDetail(projectId) {
      this.$post(
        "/projectCourseParam/edu-project-course-parameter-private/getInfo",
        { projectId }
      ).then((res) => {
        if (res.status == "0") {
          
          this.Form = {
            ...res.data.eduProjectCourseParameterPrivate,
            //  新增的
            paraIsPopQuestion:
              res.data.eduProjectCourseParameterPrivate.paraIsPopQuestion ||
              false,
            paraPopTimeLimit:
              res.data.eduProjectCourseParameterPrivate.paraPopTimeLimit ||
              "10",
            paraPopNum:
              res.data.eduProjectCourseParameterPrivate.paraPopNum || "3",
            //
            // paraTimeLimit: JSON.parse(
            //   res.data.eduProjectCourseParameterPrivate.paraTimeLimit || "[]"
            // ),
            studyLength:
              res.data.eduProjectCourseParameterPrivate.paraTimeLimit !== "[{}]"
                ? true
                : false,
            paraExamHint:
              res.data.eduProjectCourseParameterPrivate.paraExamHint,
            paraCompleteCondition: res.data.eduProjectCourseParameterPrivate
              .paraCompleteCondition
              ? true
              : false,
            paraCompleteConditionNum: res.data.eduProjectCourseParameterPrivate
              .paraCompleteCondition
              ? res.data.eduProjectCourseParameterPrivate.paraCompleteCondition
              : "80",
            projectCode: res.data.projectCode,
            projectName: res.data.projectName,
            xxsjlx:
              res.data.eduProjectCourseParameterPrivate.studyTimeLimitType,
          };
          (this.Form.paraTimeLimit = JSON.parse(
            res.data.eduProjectCourseParameterPrivate.paraTimeLimit || "[]"
          )),
            this.Form.paraTimeLimit.map((el) => {
              if (this.$route.query.personType == el.personType) {
                el.show = true;
              } else {
                el.show = false;
              }
            });
          console.log(res.data.projectCode);
          //   if (!this.Form.eduProjectCourseParameterPrivate.paraTimeLimit.length) {
          //     this.Form.eduProjectCourseParameterPrivate.paraTimeLimit.push({ ...this.Form.obj });
          //   }
        }
      });
    },
    // 选择类型
    changePersonType(val, ind) {
      // console.log(val);
      this.paraTimeLimit[ind].personType = val;
    },
    /* 特殊完成条件Change事件 */
    // handleSpecialConditions(val) {
    //   this.Form.paraSpecialConditions = val
    //   if(this.Form.paraSpecialConditions == false) {
    //     this.ProgressDisabled = true;
    //     this.Form.paraProgress = '80';
    //   } else {
    //       this.ProgressDisabled = false;
    //   }
    // }
  },
};
</script>
<style lang="less"></style>
<style lang="less">
.addClassSubject {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 100%;
      padding-left: 10rem;
    }
    .padd0 {
      padding-left: 0;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }
  .paraCompleteConditionInput {
    display: inline-block;
    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 10px;
      height: 32px;
    }
  }
}
</style>
